@import '@/styles/colors.module';

.wrapper {
  margin-top: 8px;
}

.left {
  width: 545px;

  .statusIcon {
    display: inline-block;
    margin-right: 8px;
    margin-bottom: -2px;
  }

  .title {
    font-weight: 500;
    font-size: 20px !important;
    line-height: 24px !important;
    margin-bottom: 4px;
  }

  .childrenList {
    margin-top: 4px;

    .childLink {
      color: $light_blue;

      &:hover {
        color: $blue;
      }
    }
  }
}

.right {
  width: 330px;

  .button {
    align-self: flex-start;
    padding: 0 !important;
    height: 22px !important;
    display: flex;
    align-items: center;
  }

  .row {
    display: flex;
    gap: 9px;
    font-size: 14px;
    line-height: 18px;
    color: $light_gray;
    font-weight: 500;

    svg {
      min-width: 16px;
    }
  }
}
