@import '@/styles/colors.module';
@import '@/styles/mixins';
@import "column-widths";

.column {
  &Date {
    width: $date-column-width;
  }

  &Name {
    width: $name-column-width;
  }

  &Method {
    width: $method-column-width;
  }

  &Price {
    width: $price-column-width;
  }

  &Actions {
    margin-left: auto !important;
  }
}

.header {
  height: 96px;
  padding: 32px 64px;
  background-color: $white;

  .title {
    min-width: 402px;
    font-size: 32px;
    line-height: 28px;
    font-weight: 600;
  }

  .searchInput {
    max-width: 468px;
  }
}

.results {
  background: $gray;
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  .listHeader {
    max-width: 1360px;
    margin: 0 32px;
    font-size: 16px;
    font-weight: 500;
    align-items: center;
  }

  .resultItems {
    max-width: 1418px;
  }
}
