@import '@/styles/colors.module';
@import '@/styles/mixins';
@import "@/widgets/NmckHistory/column-widths";

.card {
  height: 64px;
  cursor: pointer;
  padding: 16px 32px;
  margin: 4px 0;
  border-radius: 8px;
  background: $white;

  &:hover {
    background: #fafafa;
  }

  &Date,
  &Method,
  &Total {
    color: $light_gray;
  }

  &Name {
    font-weight: 500;
    color: $light_blue;
    @include truncate();
  }

  &Delete {
    color: $light_gray !important;

    &:hover {
      color: $red !important;
    }
  }

  &Actions {
    flex-grow: 1;
  }
}

.column {
  &Date {
    width: $date-column-width;
  }

  &Name {
    width: $name-column-width;
  }

  &Method {
    width: $method-column-width;
  }

  &Price {
    width: $price-column-width;
  }
}


.highlighted {
  animation: highlight 0.7s forwards;
}

@keyframes highlight {
  0% {
    max-height: 0;
    opacity: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
  100% {
    max-height: 64px;
    opacity: 1;
    padding-top: 16px;
    padding-bottom: 16px;
  }
}

.removed {
  animation: remove 0.7s forwards;
}

@keyframes remove {
  0% {
    max-height: 64px;
    opacity: 1;
    padding-top: 16px;
    padding-bottom: 16px;
  }
  100% {
    max-height: 0;
    opacity: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
}